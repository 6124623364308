$(document).ready(function () {

  new WOW().init();
  
  $('.hamburger').click(function () {
    $('.nav').slideToggle();
  })


  $('.popup__items').slick({
    infinite: true,
    arrows: false,
    dots: false,
    autoplay: true,
    fade: true
  });

  $('.popup__close').click(function() {
    $('.popup').addClass('popup--hidden');
  })

  function func() { 
    $('.popup').removeClass('popup--hidden'); 
    $.cookie('cookie_name', 'on'); 
  } 

  if ($.cookie('cookie_name') != 'on') { 
    setTimeout(func, 5000); 
  }

  $('.team__item').click(function() {
    $('.team__popup--active').removeClass('team__popup--active');
    $(this).next().addClass('team__popup--active');
  })

  $('.team__popup__close').click(function() {
    $('.team__popup--active').removeClass('team__popup--active');
  })

})
